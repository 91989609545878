.faq-form {
  height: 0;
  transition: all 0.15s ease-in-out;
}
.faq-form.show {
  height: auto;
}
.collapsable-form {
  height: 0;
  transition: all 0.15s ease-in-out;
  > div {
    overflow: hidden;
    height: 0;
  }
  &.show {
    height: auto;
    > div {
      overflow: auto;
      height: auto;
    }
  }
}
