// stylelint-disable declaration-no-important

// Common values
@each $position in $positions {
  .position-#{$position} {
    position: $position !important;
  }
}

// Shorthand

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zindex-fixed;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-fixed;
}

.sticky-top {
  @supports (position: sticky) {
    position: sticky;
    top: -1px;
    z-index: $zindex-sticky;
  }
}

@include media-breakpoint-down(xl) {
  .fixed-top-xl {
    position: fixed;
    top: 0;
    z-index: $zindex-fixed;
  }

  .fixed-bottom-xl {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-fixed;
  }
  .fixed-right-xl {
    position: fixed;
    right: 0;
    z-index: $zindex-fixed;
  }

  .fixed-left-xl {
    position: fixed;
    left: 0;
    z-index: $zindex-fixed;
  }
  .fixed-none-xl {
    position: auto;
    z-index: none;
  }
  .top-50-xl {
    top: 50px;
  }
  .top-100-xl {
    top: 100px;
  }
}
@include media-breakpoint-down(lg) {
  .fixed-top-lg {
    position: fixed;
    top: 0;
    z-index: $zindex-fixed;
  }

  .fixed-bottom-lg {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-fixed;
  }
  .fixed-right-lg {
    position: fixed;
    right: 0;
    z-index: $zindex-fixed;
  }

  .fixed-left-lg {
    position: fixed;
    left: 0;
    z-index: $zindex-fixed;
  }
  .fixed-none-lg {
    position: auto;
    z-index: none;
  }
  .top-50-lg {
    top: 50px;
  }
  .top-100-lg {
    top: 100px;
  }
}
@include media-breakpoint-down(md) {
  .fixed-top-md {
    position: fixed;
    top: 0;
    z-index: $zindex-fixed;
  }

  .fixed-bottom-md {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-fixed;
  }
  .fixed-right-md {
    position: fixed;
    right: 0;
    z-index: $zindex-fixed;
  }

  .fixed-left-md {
    position: fixed;
    left: 0;
    z-index: $zindex-fixed;
  }
  .fixed-none-md {
    position: auto;
    z-index: none;
  }
  .top-50-md {
    top: 50px;
  }
  .top-100-md {
    top: 100px;
  }
}
@include media-breakpoint-down(sm) {
  .fixed-top-sm {
    position: fixed;
    top: 0;
    z-index: $zindex-fixed;
  }

  .fixed-bottom-sm {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-fixed;
  }
  .fixed-right-sm {
    position: fixed;
    right: 0;
    z-index: $zindex-fixed;
  }

  .fixed-left-sm {
    position: fixed;
    left: 0;
    z-index: $zindex-fixed;
  }
  .fixed-none-sm {
    position: auto;
    z-index: none;
  }
  .top-50-sm {
    top: 50px;
  }
  .top-100-sm {
    top: 100px;
  }
}
.top-50 {
  top: 50px !important;
}
@include media-breakpoint-down(sm) {
  .top-100-sm {
    top: 100px !important;
  }
}
.top-50-1rem {
  top: calc(50px + 1rem) !important;
}

.zindex-900 {
  z-index: 900;
}
.zindex-950 {
  z-index: 950;
}

.zindex-1025 {
  z-index: 1025;
}
.zindex-1035 {
  z-index: 1035;
}
