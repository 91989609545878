$type-0: #2c3e50 !default;
$type-1: #c44569 !default;
$type-2: #0097e6 !default;
$type-3: #f39c12 !default;
$type-4: #6c5ce7 !default;
$type-5: #60a3bc !default;
$type-6: #6ab04c !default;
$type-7: #eb4d4b !default;
$type-8: #e17055 !default;
$type-9: #f78fb3 !default;

.rbc-time-view {
  border: none !important;
}

.rbc-month-view {
  min-height: calc(100vh - 270px) !important;
  flex: none !important;
}
.rbc-today {
  background-color: rgba(82, 186, 152, 0.25) !important;
}

.rbc-current-time-indicator {
  background-color: $primary !important;
}

.rbc-off-range-bg {
  background: $gray-200 !important;
}
.calendar-container {
  .rbc-calendar {
    .rbc-toolbar {
      position: sticky;
      top: 50px;
      background: white;
      z-index: 1015;
      padding: 10px 0 15px;
      margin-bottom: 0px !important;

      button {
        color: $gray-700 !important;
      }

      button:active,
      button.rbc-active {
        background-color: $primary !important;
        border-color: $gray-200 !important;
        color: $white !important;
      }

      .rbc-toolbar-label {
        font-weight: 600;
        font-size: 17px;
      }
    }
  }
  @include media-breakpoint-down(md) {
    overflow-x: auto;
    .rbc-calendar {
      min-width: 1000px;
    }
  }
}
.rbc-timeslot-group {
  border-color: $gray-200 !important;
}

.rbc-event {
  background-color: $gray-500 !important;
}

.rbc-time-view .rbc-row {
  min-height: 60px !important;
  position: relative;
}

.rbc-header {
  border-bottom: 0px !important;
  line-height: 30px;
  font-size: 20px !important;
  font-weight: normal !important;
  white-space: normal !important;
  padding: 0 !important;
}

.rbc-month-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.rbc-time-slot {
  border-top: none !important;
  margin-top: -12px;
}

.rbc-time-header {
  position: sticky;
  top: 105px;
  background: #fff;
  z-index: 1015;
  border-bottom: 1px solid #ddd;
}

.rbc-time-header-content {
  border-left: none !important;
}

.rbc-label {
  background: $white;
  font-size: 85%;
  padding: 0 5px 0 5px !important;
  margin: 0 15px 0 0;

  &.rbc-time-header-gutter {
    margin: 0;
    background: transparent;
  }
}

.rbc-time-column .rbc-timeslot-group:first-child {
  font-size: 0;
}

.rbc-header + .rbc-header {
  border-left: none !important;
}

.rbc-header a {
  padding: 0 5px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    height: 20px;
    width: 100%;
    bottom: 0px;
    border-left: 1px solid #ddd;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
}

.rbc-time-content {
  border-top: 1px solid transparent !important;
}

.rbc-time-view .rbc-allday-cell {
  display: none;
}

.rbc-month-row {
  flex-basis: auto !important;
}

.rbc-date-cell {
  min-height: 100px;
}

.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  border-left: 1px solid #ddd;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  padding: 15px 10px !important;
}

.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  text-align: center !important;
}

.rbc-agenda-view table.rbc-agenda-table thead > tr > .rbc-header + .rbc-header {
  border-left: 1px solid #ddd !important;
}

.rbc-agenda-date-cell {
  font-weight: 600;
}

.rbc-day-slot {
  .rbc-event,
  .rbc-background-event {
    min-height: 0px !important;
    flex-flow: column-reverse nowrap !important;
    overflow: unset !important;
    border: 1px solid transparent !important;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);

    .rbc-event-label {
      font-size: 10px !important;
      flex: auto !important;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    .rbc-event-content {
      white-space: nowrap;
      line-height: 1.6;
      height: auto;
      text-overflow: ellipsis;
      flex: none;
      width: 100%;
      overflow: hidden;
    }

    .rbc-events-container {
      margin-right: 0px;
    }
    &.meeting-type-0 {
      background: $type-0 !important;
    }
    &.meeting-type-1 {
      background: $type-1 !important;
    }
    &.meeting-type-2 {
      background: $type-2 !important;
    }
    &.meeting-type-3 {
      background: $type-3 !important;
    }
    &.meeting-type-4 {
      background: $type-4 !important;
    }
    &.meeting-type-5 {
      background: $type-5 !important;
    }
    &.meeting-type-6 {
      background: $type-6 !important;
    }
    &.meeting-type-7 {
      background: $type-7 !important;
    }
    &.meeting-type-8 {
      background: $type-8 !important;
    }
    &.meeting-type-9 {
      background: $type-9 !important;
    }
  }
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  &.meeting-type-0 {
    background: $type-0 !important;
  }
  &.meeting-type-1 {
    background: $type-1 !important;
  }
  &.meeting-type-2 {
    background: $type-2 !important;
  }
  &.meeting-type-3 {
    background: $type-3 !important;
  }
  &.meeting-type-4 {
    background: $type-4 !important;
  }
  &.meeting-type-5 {
    background: $type-5 !important;
  }
  &.meeting-type-6 {
    background: $type-6 !important;
  }
  &.meeting-type-7 {
    background: $type-7 !important;
  }
  &.meeting-type-8 {
    background: $type-8 !important;
  }
  &.meeting-type-9 {
    background: $type-9 !important;
  }
}

.rbc-timeslot-group {
  min-height: 40px !important;
}
.calendar-week-view {
  .rbc-events-container > .rbc-event {
    &::before,
    &::after {
      color: #fff;
      font-size: 16px;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      text-align: center;
      z-index: 1000;
    }

    &::before {
      position: absolute;
      bottom: calc(100% + 5px);
      left: 50%;
      transform: translateX(-50%);
      margin: 0 auto;
      background-color: #2f3136;
      border-radius: 5px;
      color: #fff;
      content: attr(title);
      white-space: nowrap;
      padding: 0.5rem;
      text-transform: none;
      transition: all 0.5s ease;
      width: max-content;
      z-index: 1001;
    }

    &::after {
      position: absolute;
      top: -5px;
      left: 50%;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #2f3136;
      content: ' ';
      font-size: 0;
      line-height: 0;
      margin-left: -5px;
      width: 0;
    }

    &:hover:before,
    &:hover:after {
      opacity: 1;
      visibility: visible;
      transition: all 0.75s ease;
    }

    .rbc-event-content {
      max-height: 100%;
    }
  }
}
.calendar-week-view {
  .rbc-event-label {
    font-size: 10px !important;
    flex: auto !important;
  }

  .rbc-event-content {
    font-size: 12px !important;
    white-space: nowrap;
    line-height: 1;
    height: auto;
    text-overflow: ellipsis;
  }
}

.calendar-day-view {
  .rbc-timeslot-group {
    min-height: 135px !important;
  }
  .rbc-event,
  .rbc-background-event {
    .rbc-event-content {
      font-size: 15px !important;
    }
  }
}
.rbc-agenda-event-cell {
  cursor: pointer;
  text-decoration: underline;
}

.calendar-sidebar {
  position: relative;
  z-index: 1020;
  @include media-breakpoint-down(md) {
    position: absolute;
    height: 100%;
  }
}
@include media-breakpoint-down(md) {
  .rbc-toolbar {
    top: 0px !important;
  }
  .rbc-time-header {
    top: 0px;
  }
}
@include media-breakpoint-down(sm) {
  .calendar-sidebar {
    .top-50 {
      top: 100px !important;
    }
  }
}

@media (max-width: 390px) {
  .rbc-time-header {
    top: 210px;
  }
}
